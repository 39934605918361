import FullWidthSection from "../../../section/FullWidthSection";
import Section from "../../../section/Section";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../../hooks/useTypeForm";

const AmbitiousSection = () => {
	const { event } = useAnonymousDataLayer();
	const freeConsultationForm = useTypeForm("R6iu6OZO");
	return (
		<FullWidthSection
			bgColor="red"
			className="py-10 py-md-20 px-4 d-flex justify-content-center align-items-center"
			containerClassName="ambitious-section row bg-white pt-7 pb-12 pt-md-15 pb-md-10 px-6 px-md-20"
		>
			<div className="col-12 col-md-6 text-center text-md-left">
				<h2 className="pb-4 pb-md-6">Ambitious leaders aren’t born, they’re coached.</h2>
				<p className="font-size-lg m-0">
					Speak to an expert about how we plug-in to your organisation and how we can support and accelerate the
					development of your future leaders.
				</p>
			</div>
			<div className="d-none d-md-block col-md-2" />
			<div className="col-12 col-md-4 d-flex align-items-center justify-content-center pt-6 pt-md-0">
				<a href="/for-organisations" className="btn btn-cornered btn-white" target="_blank">
					Learn more
				</a>
				<Button
					className="btn-cornered btn-black ml-3"
					onClick={() => {
						event("Book free consultation", "coaching", "Organisations interaction");
						freeConsultationForm.open();
					}}
				>
					Book a call
				</Button>
			</div>
		</FullWidthSection>
	);
};

export default AmbitiousSection;
